<template>
  <div>
    <header>
      <div class="header-wrap">
        <div class="left">
          宵略网络
        </div>
        <div class="right">
          <ul>
            <li><a href="#connect">联系我们</a></li>
            <li><a href="#about">关于我们</a></li>
            <li><a href="#serviceItem">服务项目</a></li>
            <li><a href="#caseShow">案例展示</a></li>
            <li><a href="#home">首页</a></li>
          </ul>
        </div>
      </div>
    </header>
    <a-carousel arrows autoplay>
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px; zindex: 1">
          <LeftOutlined />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 60px">
          <RightOutlined />
        </div>
      </template>
      <div class="img-item">
        <div class="text">
          <p>用整合的思想做互动</p>
          <p>不只是建站，更提供有价值的思路和整体服务</p>
        </div>
        <img style="width: 100vw; height: 800px" src="./assets/02.jpg" alt="1633406078803966">
      </div>
      <div class="img-item">
        <div class="text">
          <p>9年资深网站建设经验</p>
          <p>我们专注于品质化高端网站构建，企业品牌在网站中的整体树立，网络互动的体验，以及在手机等移动端的优质呈现。</p>
        </div>
        <img style="width: 100vw; height: 800px" src="./assets/05.jpg" alt="1633406086834540">
      </div>
      <div class="img-item">
        <div class="text">
          <p>高端定制 品牌设计</p>
          <p>致力于为客户提供最具有竞争力的网站，将每个案例都做成经典。</p>
        </div>
        <img style="width: 100vw; height: 800px" src="./assets/07.jpg" alt="1633406086834540">
      </div>
    </a-carousel>
    <div class="service-item" id="serviceItem">
      <div class="title">服务项目</div>
      <div class="logo">
        <img src="./assets/i1.png" alt="">
      </div>
      <div class="describe">我们续写的是行业历史的专业化，我们创新的是资源、标准与技术， HTML5的到来，更加证明了团队十年磨一剑的真实与优势！</div>
      <div class="item-list">
        <div class="one">
          <img src="./assets/1633406524154900.jpg" alt="1633406524154900">
          <div class="name">网站建设</div>
          <div class="text">根据不同设备屏幕尺寸自动适配调整页面布局和结构，不用放大缩小浏览</div>
        </div>
        <div class="two">
          <img src="./assets/1633406535396121.jpg" alt="1633406535396121">
          <div class="name">手机APP</div>
          <div class="text">资深技术团队，紧跟技术前沿，不断升级完善基于用户体验为核心需求开发的的移动软件</div>
        </div>
        <div class="third">
          <img src="./assets/1633406544801123.jpg" alt="1633406544801123">
          <div class="name">小程序</div>
          <div class="text">智能连接人与信息、人与服务、人与万物的开放生态，精准连接用户，无需下载安装便可享受智慧超前的使用体验。</div>
        </div>
      </div>
    </div>
    <div class="case-show" id="caseShow">
      <div class="title">案例展示</div>
      <div class="logo">
        <img src="./assets/i1.png" alt="">
      </div>
      <div class="describe">我们续写的是行业历史的专业化，我们创新的是资源、标准与技术，更加证明了团队十年磨一剑的真实与优势！</div>
      <div class="item-list">
        <div class="one">
          <img width="260" src="./assets/460x0w.webp" alt="1633404185724900">
          <div class="name">
            自主研发和运营的MChat软件，是一款开放的聊天工具，通过本应用可以向好友发送文字、图片、语音等信息，还可以随时随地进行即时通话、视频聊天，
            <a href="https://mc.xiaowanj.com" target="_blank">了解更多</a>
          </div>
        </div>
        <!-- <div class="two">
          <img src="./assets/1633404185724900.png" alt="1633404185724900">
          <div class="name">案例展示2</div>
        </div>
        <div class="third">
          <img src="./assets/1633404185724900.png" alt="1633404185724900">
          <div class="name">案例展示3</div>
        </div> -->
      </div>
    </div>
    <div class="about" id="about">
      <div class="title">关于我们</div>
      <div class="logo">
        <img src="./assets/i2.png" alt="">
      </div>
      <div class="describe">
        上海宵略网络科技有限公司拥有雄厚的资金基础、人才济济的研发阵容和具有先进创想理念的运营团队。公司倡导团队协作，公平公正，强调员工与企业共成长的企业文化，
        鼓励创新致力于为员工营造充分展示自我才智、发挥潜能的舞台。 除了为员工提供极具竞争力的薪酬福利待遇外，公司还建立了一整套与员工的职业生涯发展相结合的合理晋升通道，
        专业技能优秀的员工可以按照技术路线晋升，管理才能卓越的员工可以按照职能体系晋升。员工晋升以绩效考核结果为基础，同时又作为薪酬调整的依据。公司内部空缺岗位优先考虑内部竞聘人员，
        为员工提供广阔的发展空间.企业目标： 专业、诚信、客户利益至上经营理念：不断超越，追求完美。诚信为本，创新为魂。向质量要市场，向管理要效益。服务宗旨：顾客的满意是我们矢志不移的追求！
      </div>
    </div>
    <footer id="connect">
      <div class="wrap">
        <p>公司名称：上海宵略网络科技有限公司</p>
        <p>公司地址：上海市徐汇区中同西路2020号501、502、503、504室</p>
        <p>公司邮箱：xiaolvwangluo@outlook.com</p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2020028951号-1</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue';
import carousel from 'ant-design-vue/lib/carousel'
import 'ant-design-vue/lib/carousel/style/css';
export default {
  name: 'App',
  components: {
    LeftOutlined,
    RightOutlined,
    aCarousel: carousel
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  font-size: 65px;
  color: #000;
  // background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}
.ant-carousel :deep(.slick-slide h3) {
  color: #000;
}
.slick-track {
  .img-item {
    position: relative;
    .text {
      position: absolute;
      text-align: center;
      left: calc(50% - 370px);
      top: 45%;
      width: 740px;
      p {
        margin: 0 auto;
        color: #fff;
        font-size: 35px;
      }
    }
  }
}
header {
  width: 100vw;
  height: 78px;
  background-color: #333;
  padding: 10px 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  opacity: 0.7;
  .header-wrap {
    width: 1200px;
    margin: 0px auto;
    display: flex;

    .left {
      width: 400px;
      height: 58px;
      line-height: 58px;
      font-size: 42px;
      color: #089ebc;
      text-align: center;
    }

    .right {
      width: 800px;
      height: 58px;
      line-height: 58px;
      ul {
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
          list-style: none;
          padding: 0px;
          margin: 0px;
          float: right;
          width: 120px;
          font-size: 22px;
          margin: 0 15px;
          list-style-type: none;
          cursor: pointer;
          color: #fff;
          text-align: center;
          a {
            color: #fff;
          }
          a:hover {
             color: #089ebc;
          }
        }

        li:hover {
          color: #089ebc;
        }
      }
    }
  }
}

footer {
  width: 100vw;
  height: 150px;
  background-color: #333;
  .wrap {
    width: 1200px;
    height: 150px;
    color: #ccc;
    margin: 0 auto;
    padding: 20px 0;
    p { 
      line-height: 28px;
      margin: 0;
      font-size: 15px;
      a {
        color: #ccc;
      }
      a:hover {
        color: #fff;
      }
    }
  }
}

.service-item {
  width: 100vw;
  height: 590px;
  padding: 60px 0;

  .title {
    width: 1200px;
    height: 42px;
    font-size: 28px;
    color: #333;
    text-align: center;
    margin: 0 auto;
  }
  .logo {
    width: 1200px;
    height: 42px;
    margin: 20px auto;
    text-align: center;
  }
  .describe {
    width: 1200px;
    height: 24px;
    color: #666;
    margin: 0 auto;
    text-align: center;
  }
  .item-list {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    margin-top: 50px;
    .one,.two,.third {
      width: 400px;
      height: 250px;
      text-align: center;
      .name {
        margin-top: 10px;
        height: 46px;
        line-height: 46px;
        font-size: 18px;
        color: #333;
      }
      .text {
        width: 240px;
        text-align: center;
        font-size: 16px;
        color: #666;
        margin: 0 auto;
      }
    }
  }
}

.case-show {
  width: 100vw;
  padding: 60px 0;
  background-color: #f2f2f2;
  .title {
    width: 1200px;
    height: 42px;
    font-size: 28px;
    color: #333;
    text-align: center;
    margin: 0 auto;
  }
  .logo {
    width: 1200px;
    height: 42px;
    margin: 20px auto;
    text-align: center;
  }
  .describe {
    width: 1200px;
    height: 24px;
    color: #666;
    margin: 0 auto;
    text-align: center;
  }
  .item-list {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    .one,.two,.third {
      // width: 400px;
      // height: 250px;
      text-align: center;
      margin: 0 auto;
      // display: flex;
      img {
        border-radius: 10px;
        margin-right: 20px;
        margin-bottom: 30px;
      }
      .name {
        width: 660px;
        text-align: left;
        // margin-top: 10px;
        height: 46px;
        line-height: 36px;
        font-size: 18px;
        color: #666;
      }
      .text {
        width: 240px;
        text-align: center;
        font-size: 16px;
        color: #666;
        margin: 0 auto;
      }
    }
  }
}
.about {
  width: 100vw;
  padding: 60px 0 150px 0;
  .title {
    width: 1200px;
    height: 42px;
    font-size: 28px;
    color: #333;
    text-align: center;
    margin: 0 auto;
  }
  .logo {
    width: 1200px;
    height: 42px;
    margin: 20px auto;
    text-align: center;
  }
  .describe {
    width: 1200px;
    // height: 24px;
    line-height: 30px;
    color: #666;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
  }
}
</style>
